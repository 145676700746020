<template>
  <div>
    <div class="w-50 mx-auto" v-if="submitted">
      <loader-info></loader-info>
    </div>
    <div v-if="completed">
      <div class="text-center">
        <font-awesome-icon icon="check-circle" class="text-success fa-5x my-3" />
        <h5>
          <strong>
            Segnalazione inviata con successo
          </strong>
        </h5>
      </div>
      <div class="mt-5 text-center">
        <p>
          La tua segnalazione è stata presa in carico da Centrale Rischi Pagamenti, che la
          renderà visibile previa approvazione nel profilo del soggetto segnalato.
          <br>
          Puoi incrementare ulteriormente l’efficacia della tua segnalazione inserendo uno o più
          documenti probatori del comportamento/evento segnalato.
        </p>
        <p>
          Ricorda che, qualora il soggetto segnalato replichi alla tua segnalazione, avrai la
          possibilità di replicare aprendo un contraddittorio.
        </p>
      </div>
    </div>
    <div v-if="hasError" class="text-center">
      <font-awesome-icon icon="exclamation-circle" class="text-danger fa-5x my-3" />
      <div class="mt-3">
        <h5>
          <strong>
            Si è verificato un errore con la segnalazione.
          </strong>
        </h5>
      </div>
      <p class="text-underline">
        <router-link to="/">Torna alla dashboard</router-link>
      </p>
    </div>
    <div
      v-if="completed"
      class="mt-5 px-4 border-top pt-3 mx-n3"
    >
      <attach-documents
        :entity-class="entityClass"
        :entity-id="entityId"
        document-entity-type="USERREPORT"
        :documents="documents"
        :on-success="setDocuments"
      />
    </div>
  </div>
</template>

<script>
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const AttachDocuments = () => import('@/components/wizards/userReportSections/AttachDocuments.vue');

export default {
  name: 'ReportUsefulDocuments',
  components: { AttachDocuments, LoaderInfo },
  props: {
    submitted: Boolean,
    completed: Boolean,
    hasError: Boolean,
    entityId: String,
    entityClass: String,
    documentEntityType: String,
  },
  data() {
    return {
      documents: [],
    };
  },
  methods: {
    setDocuments(data) {
      if (data) {
        this.documents = [...data];
      }
    },
  },
};
</script>

<style scoped>

</style>
